import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "./../../components/layout";
import SEO from "./../../components/seo";
import Img from "gatsby-image";
import { OutboundLink } from 'gatsby-plugin-google-analytics';

// Import CSS
import "./../../styles/industry.css";

// Import components
import GetStartedBanner from "./../../components/get-started-footer";

const SaaSIndustryPage = ({ data }) => (
  <Layout>
    <SEO
      title="Keep your SaaS online with website and API monitoring"
      description="Never worry about unexpected downtime disrupting your SaaS application. Odown's powerful monitoring tool tracks performance 24/7 and alerts you immediately to any problems, so you can take fast action."
      pathname={`/industry/saas/`}
      image={{
        src: "/general-image-og--odown_for_saas.jpg",
        width: 1600,
        height: 800,
      }}
    />
    <HeadingSection />
    <MonitorSaaSSection />
    <KnowFirstSection imageData={data.FeatureAlerting.childImageSharp.fluid} />
    <LightningSpeedSection imageData={data.FeatureBreakDown.childImageSharp.fluid} />
    <GetStartedBanner />
  </Layout>
);

const HeadingSection = () => {
  const [getStartedButton, setGetStartedButton] = useState({
    name: "Get started for free",
    link: "https://app.odown.io/signup",
  });

  return (
    <div className="u-features saas-hero">
      <div className="container">
        <h1 className="u-features__heading-title">
          The Preferred Website Monitoring Solution for SaaS Companies
        </h1>
        <p className="hero_sub-title">
          When your customers expect a fast and functional service, Odown can check your software continuously from your customers' locations.
        </p>
      </div>
      <div className="u-features__heading-btns center-button">
        <OutboundLink
          className="btn btn-primary"
          eventCategory="Launch the Odown App"
          eventAction="Click"
          eventLabel="Goto signup"
          href={getStartedButton.link}
          target="_blank"
        >
          {getStartedButton.name}
        </OutboundLink>
      </div>
    </div>
  );
};

const MonitorSaaSSection = () => {
    return (
      <section className="u-main-feature monitor-saas-section">
        <div className="container">
          <div className="u-main-feature__wrapper">
            <div className="u-main-feature__text-content">
              <h2 className="u-main-feature_tc-title1">
                We monitor your SaaS to make sure your services are always up and running
              </h2>
              <p className="u-main-feature_tc-desc2">
                Your reputation takes a big hit when your SaaS product goes down. This directly damages your marketing, sales, and ultimately, revenue. Using Odown’s Website Monitoring & Status Pages, you’ll know if your service hits an issue, and so will your customers.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  };
  

const KnowFirstSection = ({ imageData }) => {
    return (
      <section className="u-main-feature know-first-section">
        <div className="container">
          <div className="u-main-feature__wrapper">
            <div className="u-main-feature__text-content">
              <h2 className="u-main-feature_tc-title">
                Be the first to know that your SaaS application is working correctly
              </h2>
              <div className="formatted-text">
                <p className="u-main-feature_tc-desc">
                  Knowing your web app and supporting API endpoints work as intended is vital.
                </p>
                <p className="u-main-feature_tc-desc indented">
                  &#10004; <strong>Do your critical customer journeys work as intended?</strong> The service has optimal process flows to customer goals. Odown’s Web Monitoring can step through complex user actions and verify their function and speed.
                </p>
                <p className="u-main-feature_tc-desc indented">
                  &#10004; <strong>Is your product API responding as expected?</strong> If critical endpoints requests start failing, your team needs to know. Odown’s API Monitoring is the ultimate solution for monitoring your API responses.
                </p>
              </div>
            </div>
            <div className="u-main-feature__media-content">
              <div className="illustration-wrapper">
                <Img
                  fadeIn={false}
                  loading="eager"
                  fluid={imageData}
                  alt="Industry - Alerting for any situation"
                />
              </div>
            </div>
          </div>
          <div className="tableResponsive-industry">
            <div className="u-main-feature-table">
              <div className="u-main-feature-column">
                <h3>Monitor to stay competitive</h3>
                <p className="u-main-feature_tc-desc1">
                  Having high availability and a service faster than the competition is the perfect match. Odown can help you build out a baseline and make sure your product stays within those margins.
                </p>
              </div>
              <div className="u-main-feature-column">
                <h3>Not just a tool for production</h3>
                <p className="u-main-feature_tc-desc1">
                  Use Odown monitoring in your dev environment as well as in your acceptance environments to test before it goes out into production.
                </p>
              </div>
              <div className="u-main-feature-column">
                <h3>Monitoring with global checkpoints</h3>
                <p className="u-main-feature_tc-desc">
                  Just because your SaaS works in one country doesn’t guarantee it’s working everywhere. Monitor your service from 17 global checkpoints. Test from multiple locations simultaneously.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  

const LightningSpeedSection = ({ imageData }) => {
  return (
    <section className="u-main-feature lightning-speed-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={imageData}
                alt="Industry - Lightning speed for SaaS"
              />
            </div>
          </div>
          <div className="u-main-feature__text-content">
            <h2 className="u-main-feature_tc-title">
              Lightning speed is a must for a SaaS
            </h2>
            <div className="formatted-text">
              <p className="u-main-feature_tc-desc">
                If your service is slow, you’re losing confidence with customers.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Drive a positive brand image.</strong> Use Odown to ensure customers get fast responses and load times. Page and API speed increase the perception of your brand and service.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Monitor latency from your customers' locations.</strong> Network latency can change depending on localized internet provider changes. Take use of our access to regional datacenters around the world to monitor local uptime.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  query {
    FeatureAlerting: file(
      relativePath: { eq: "product__api-monitoring__option__alerting.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    FeatureBreakDown: file(
      relativePath: { eq: "product__api-monitoring__option__break-down.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;

export default SaaSIndustryPage;
